import { useQuery } from '@tanstack/react-query';
import {
  requestTemperatureStatistics,
  requestTemperatureStatisticsWeek,
} from 'api/temperature';
import useControllerStore from 'store/controllerStore';
import useFarmStore from 'store/farmStore';

export const TEMPERATURE_QUERY_KEY = 'temperature';
export const TEMPERATURE_WEEK_QUERY_KEY = 'temperature_week';
export const useTemperatureStatisticQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { currentControllerNumber: controllerNumber } = useControllerStore();

  return useQuery({
    queryKey: [TEMPERATURE_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestTemperatureStatistics({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 30 * 1000, //30초
    refetchInterval: 30 * 1000,
    cacheTime: 10 * 60 * 1000, //10분
  });
};
export const useTemperatureStatisticWeekQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { currentControllerNumber: controllerNumber } = useControllerStore();

  return useQuery({
    queryKey: [TEMPERATURE_WEEK_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestTemperatureStatisticsWeek({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 60 * 60 * 1000, //1시간
    refetchInterval: 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000, //12시간
  });
};
