import clsx from 'clsx';
import useFarmTypeStore from 'store/master/farmTypeStore';

export default function AddRailTypeForm() {
  const { railtypeInfo, setRailtypeInfo } = useFarmTypeStore();

  return (
    <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-x-5 gap-y-1">
      <div className="flex items-center gap-x-5">
        <label htmlFor="railtype-form-celltype" className={labelStyle}>
          셀 타입
        </label>
        <input
          type="text"
          disabled
          value={'밀웜'}
          className={inputStyle}
        ></input>
      </div>
      <div className="flex items-center gap-x-5">
        <label htmlFor="railtype-form-celltype" className={labelStyle}>
          라인 수<br />
          (even)
        </label>
        <input
          type="number"
          min={0}
          value={railtypeInfo.lineCount}
          onChange={(e) => {
            const newLineCount = parseInt(e.target.value);
            setRailtypeInfo({
              ...railtypeInfo,
              lineCount: newLineCount,
            });
          }}
          className={inputStyle}
        ></input>
      </div>
      <div className="flex items-center gap-x-5">
        <label htmlFor="railtype-form-celltype" className={labelStyle}>
          라인당 블록
        </label>
        <input
          type="number"
          className={inputStyle}
          value={railtypeInfo.blockCount}
          onChange={(e) => {
            const newBlockCount = parseInt(e.target.value);
            setRailtypeInfo({
              ...railtypeInfo,
              blockCount: newBlockCount,
            });
          }}
        ></input>
      </div>
      <div className="flex items-center gap-x-5">
        <label htmlFor="railtype-form-celltype" className={labelStyle}>
          박스 층 수
        </label>
        <input
          type="number"
          className={inputStyle}
          value={railtypeInfo.boxCount}
          onChange={(e) => {
            const newBoxCount = parseInt(e.target.value);
            setRailtypeInfo({
              ...railtypeInfo,
              boxCount: newBoxCount,
            });
          }}
        ></input>
      </div>
    </div>
  );
}
//style
const labelStyle = clsx('text-sm text-subText min-w-[40px] whitespace-nowrap');
const inputStyle = clsx(
  'border-subLine border rounded-md px-2.5 py-3 flex-1',
  'focus:outline-none',
  'font-bold text-sm',
);
