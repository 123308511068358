import clsx from 'clsx';

import MasterHeader from 'components/master/MasterHeader';
import { Route, Routes } from 'react-router-dom';
import FarmListPage from './FarmListPage';
import FarmEditPage from './FarmEditPage';

export default function Page() {
  return (
    <div className={clsx('relative text-mainText')}>
      <MasterHeader />
      {/* 화면의 가운데 위치 */}
      <div
        id="master-container"
        className="h-screen overflow-y-auto pt-headerHeight"
      >
        <Routes>
          <Route path="/" element={<FarmListPage />} />
          <Route path="/:farmId" element={<FarmEditPage />} />
        </Routes>
      </div>
    </div>
  );
}
