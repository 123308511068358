import { useTwinstarFarmQuery } from 'hooks/reactQuery/master/twinstarFarmQuery';
import { useEffect } from 'react';
import { Access } from 'types/types';
import useFarmTypeStore from 'store/master/farmTypeStore';
import { useRailStructureQuery } from 'hooks/reactQuery/worm/railStructureQuery';
import { useAllFarmListQuery } from 'hooks/reactQuery/master/allFarmListQuery';
import useFarmEditInfoStore from 'store/master/farmEditInfoStore';
import { getCountryName } from 'utils/master/farm';

type Params = {
  targetFarmNumber: number;
  access: Access;
};
export default function useEditInit({ targetFarmNumber, access }: Params) {
  const {
    setRacktypeInfo,
    setVisibility,
    setChangeable,
    setRailtypeInfo,
    setIsExist,
  } = useFarmTypeStore();
  const { setFarmInfo } = useFarmEditInfoStore();
  //농장 정보 가져오기
  const { data: farms } = useAllFarmListQuery();

  // 트윈스타 농장 정보 쿼리
  const { data: twinstarfarm } = useTwinstarFarmQuery({
    targetFarmNumber,
  });

  //레일타입 농장 구조 쿼리
  const { data: railStructure } = useRailStructureQuery(
    targetFarmNumber,
    access,
  );

  // 농장 정보 초기화
  useEffect(() => {
    const farm = farms?.find((farm) => farm.id === targetFarmNumber);

    if (!farm) return;
    console.log(farm);
    setFarmInfo({
      id: farm.id,
      country: farm.country,
      name: farm.farm_name,
      address: farm.farm_address,
      access: farm.access,
    }); //농장 정보 초기화
  }, [farms]);

  //농장 Rack 타입 정보 초기화
  useEffect(() => {
    if (!twinstarfarm) return;
    if (twinstarfarm.lineCount > 0) {
      setVisibility({ rackType: true });
      setChangeable({ rackType: false }); //이미 존재하는 농장은 수정 불가능
      setIsExist({ rackType: true });
    }

    setRacktypeInfo({
      lineCount: twinstarfarm?.lineCount ?? 0,
      blockCount: twinstarfarm?.blockCount ?? 0,
      floor: twinstarfarm?.floor ?? 0,
      InsectType: twinstarfarm?.cellType ?? 1,
    });
  }, [twinstarfarm]);

  //농장 Rail 타입 정보 초기화
  useEffect(() => {
    if (!railStructure) return;
    if (railStructure.lineCount > 0) {
      setVisibility({ railType: true });
      setChangeable({ railType: false }); //이미 존재하는 농장은 수정 불가능
      setIsExist({ railType: true });
    }

    setRailtypeInfo({
      lineCount: railStructure.lineCount,
      blockCount: railStructure.blockCount,
      boxCount: railStructure.blockCount,
    });
  }, [railStructure]);
}
