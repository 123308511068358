import {
  useTemperatureStatisticQuery,
  useTemperatureStatisticWeekQuery,
} from './reactQuery/temperatureQuery';
import {
  useHumidityStatisticQuery,
  useHumidityStatisticWeekQuery,
} from './reactQuery/humidityQuery';
import {
  useCo2StatisticQuery,
  useCo2StatisticWeekQuery,
} from './reactQuery/co2Query';
import { Serie } from '@nivo/line';

export default function useEnvGraph() {
  const { data: temperatureByHours } = useTemperatureStatisticQuery();
  const { data: temperatureByDays } = useTemperatureStatisticWeekQuery();
  const { data: humidityByHours } = useHumidityStatisticQuery();
  const { data: humidityByDays } = useHumidityStatisticWeekQuery();
  const { data: co2ByHours } = useCo2StatisticQuery();
  const { data: co2ByDays } = useCo2StatisticWeekQuery();

  const temperatureByHoruSeries: Serie[] = [
    {
      id: 'temperature-hour',
      data:
        temperatureByHours?.map((item) => ({
          x: item.time,
          y: item.avg_temperature,
        })) ?? [],
    },
  ];
  const temperatureByDaySeries: Serie[] = [
    {
      id: 'temperature-day',
      data:
        temperatureByDays?.map((item) => ({
          x: item.day,
          y: item.avg_temperature,
        })) ?? [],
    },
  ];
  const humidityByHourSeries: Serie[] = [
    {
      id: 'humidity-hour',
      data:
        humidityByHours?.map((item) => ({
          x: item.time,
          y: item.avg_humidity,
        })) ?? [],
    },
  ];
  const humidityByDaySeries: Serie[] = [
    {
      id: 'humidity-day',
      data:
        humidityByDays?.map((item) => ({
          x: item.day,
          y: item.avg_humidity,
        })) ?? [],
    },
  ];
  const co2ByHourSeries: Serie[] = [
    {
      id: 'co2-hour',
      data:
        co2ByHours?.map((item) => ({
          x: item.time,
          y: item.avg_co2,
        })) ?? [],
    },
  ];
  const co2ByDaySeries: Serie[] = [
    {
      id: 'co2-day',
      data:
        co2ByDays?.map((item) => ({
          x: item.day,
          y: item.avg_co2,
        })) ?? [],
    },
  ];
  return {
    temperatureByHoruSeries,
    temperatureByDaySeries,
    humidityByHourSeries,
    humidityByDaySeries,
    co2ByHourSeries,
    co2ByDaySeries,
  };
}
