import { useQuery } from '@tanstack/react-query';
import { requestCo2Statistics, requestCo2StatisticsWeek } from 'api/co2';
import useControllerStore from 'store/controllerStore';
import useFarmStore from 'store/farmStore';

export const CO2_QUERY_KEY = 'co2';
export const CO2_WEEK_QUERY_KEY = 'co2_week';
export const useCo2StatisticQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { currentControllerNumber: controllerNumber } = useControllerStore();
  return useQuery({
    queryKey: [CO2_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestCo2Statistics({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 30 * 1000, //30초
    refetchInterval: 30 * 1000,
    cacheTime: 10 * 60 * 1000, //10분
  });
};

export const useCo2StatisticWeekQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { currentControllerNumber: controllerNumber } = useControllerStore();

  return useQuery({
    queryKey: [CO2_WEEK_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestCo2StatisticsWeek({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 60 * 60 * 1000, //1시간
    refetchInterval: 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000, //12시간
  });
};
