/* eslint-disable prettier/prettier */
import HeaderGraph from './HeaderGraph';
import IcLogo from 'components/icon/IcLogo';
import useEnvGraph from 'hooks/useEnvGraph';

type HeaderGraphContainerProps = {
  type: 'temperature' | 'humidity' | 'co2';
  periodType: 'day' | 'week';
};

export default function HeaderGraphContainer(props: HeaderGraphContainerProps) {
  const { type, periodType } = props;
  const {
    temperatureByHoruSeries,
    temperatureByDaySeries,
    humidityByHourSeries,
    humidityByDaySeries,
    co2ByHourSeries,
    co2ByDaySeries,
  } = useEnvGraph();

  if (type === 'temperature' && periodType === 'day')
    return temperatureByHoruSeries.length === 0 ? (
      <EmptyDataFallback />
    ) : (
      <HeaderGraph
        type={type}
        data={temperatureByHoruSeries}
        period={periodType}
      />
    );
  if (type === 'temperature' && periodType === 'week')
    return temperatureByDaySeries.length === 0 ? (
      <EmptyDataFallback />
    ) : (
      <HeaderGraph
        type={type}
        data={temperatureByDaySeries}
        period={periodType}
      />
    );
  if (type === 'humidity' && periodType === 'day')
    return humidityByHourSeries.length === 0 ? (
      <EmptyDataFallback />
    ) : (
      <HeaderGraph
        type={type}
        data={humidityByHourSeries}
        period={periodType}
      />
    );
  if (type === 'humidity' && periodType === 'week')
    return humidityByDaySeries.length === 0 ? (
      <EmptyDataFallback />
    ) : (
      <HeaderGraph type={type} data={humidityByDaySeries} period={periodType} />
    );
  if (type === 'co2' && periodType === 'day')
    return co2ByHourSeries.length === 0 ? (
      <EmptyDataFallback />
    ) : (
      <HeaderGraph type={type} data={co2ByHourSeries} period={periodType} />
    );
  /*type==='co2' && periodType==='week'*/

  return co2ByDaySeries.length === 0 ? (
    <EmptyDataFallback />
  ) : (
    <HeaderGraph type={type} data={co2ByDaySeries} period={periodType} />
  );
}

function EmptyDataFallback() {
  return (
    <div className="w-full h-full flexCenter">
      <IcLogo width={24} height={24} />
    </div>
  );
}
